import $ from 'jquery';
import Noty from 'noty';

import waitMe from './waitMe.min.js';
import moment from 'moment';
// import moment from 'moment-timezone'
import Hashids from 'hashids'

waitMe($);

/*
$.ajaxSetup({
	headers:{
		'Api-Token': process.env.VUE_APP_API_TOKEN,
		'Access-Control-Allow-Origin': '*'
	}
});

var base_url=process.env.VUE_APP_API_URL;
*/

export default {
	data () {
		return {
			pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: 10,
                slOffset: 1
            },
			paginationCustom: {
                currentPage: 1,
                totalRows: 0,
                perPage: 10,
                slOffset: 1
            },
			paginationVerified: {
                currentPage: 1,
                totalRows: 0,
                perPage: 10,
                slOffset: 1
            },
			paginationUnverified: {
                currentPage: 1,
                totalRows: 0,
                perPage: 10,
                slOffset: 1
            },
			countryList: [],
			monthList: [
				{ id: 1, label: 'January' },
				{ id: 2, label: 'February' },
				{ id: 3, label: 'March' },
				{ id: 4, label: 'April' },
				{ id: 5, label: 'May' },
				{ id: 6, label: 'June' },
				{ id: 7, label: 'July' },
				{ id: 8, label: 'August' },
				{ id: 9, label: 'September' },
				{ id: 10, label: 'October' },
				{ id: 11, label: 'November' },
				{ id: 12, label: 'December' },
			],
			yearList: [
				{ id: 1, label: '2019' },
				{ id: 2, label: '2020' },
				{ id: 3, label: '2021' },
				{ id: 4, label: '2022' },
				{ id: 5, label: '2023' },
				{ id: 6, label: '2024' },
				{ id: 7, label: '2025' }
			],
			verificationStatusList: [
				{ id: 'verified', label: 'Verified' },
				{ id: 'unverified', label: 'Unverified' },
			],
			issueStatusList: [
				{ value: 'all', text: 'All' },
				{ value: 'with_issue', text: 'In Progress' },
				{ value: 'no_issue', text: 'Completed' },
			],
			fundingSourceList: [
				{ id: 'Sponsored', label: 'Sponsored' },
				{ id: 'Self Funded', label: 'Self Funded' },
			],
			taskStatusList: [
				{ id: 0, label: 'Pending' },
				{ id: 1, label: 'Ongoing' },
				{ id: 2, label: 'Completed' }
			],
            agreementRenewableOption:[
                {
                    value: 1,
                    text: 'Yes',
                },
                {
                    value: 0,
                    text: 'No',
                }
            ],
            recruitmentMarketTypeList:[
                {
                    value: 0,
                    text: 'Global',
                },
                {
                    value: 2,
                    text: 'Region',
                },
                {
                    value: 1,
                    text: 'Country',
                }
            ],
		}
	},
	computed: {
        auth_user:function(){
            return this.cn(this.$store.state, 'site.auth_user', null);
        }
	},
	methods:{
		no_img: function(uri='assets/img/placeholder.png'){
			return this.url(uri);
		},
		url: function(path, base_url=null){

			if(!base_url){
				base_url=this.base_url;
			}

			return `${base_url}/${path}`;

		},
		apiUrl: function(path){
			return `${this.api_url}/${path}`;
		},
		jq:function(){
			return $;
		},
		alert:function(msg='No message specified', type='success', callback=null){

			if(!msg) return null;

			new Noty({
				theme:'sunset',
				text: msg,
				type: type,
				timeout:3000,
				callbacks:{
					afterClose: callback
				}
			}).show();

		},
		err_msg:function(err){

			//console.log(err.status);

			if(err.status && err.status==401){

				this.$router.push('/logout');
				return null;

			}

			var msg="Request failed to process, try again later.";

			if(err.responseJSON && err.responseJSON.msg){
				msg=err.responseJSON.msg;
			}else if(err.message){
				msg=err.message;
			}

			return msg;

		},
		catch_alert: async function(err, callback=null){

			this.alert(err.message, 'error', callback);

		},
		cn:function(obj, input, if_false=''){

			var elements=input.split('.');

			for(var i = 0; i < elements.length; i++){

				if(!obj || !obj[elements[i]]){

					return if_false;

				}

				obj=obj[elements[i]];
			}

			if(obj) return obj;
			return if_false;

		},
		ajax_setup:function(token_name='api_token'){

			var ref=this;
			var api_token=ref.api_token;
			
			if(localStorage.getItem(token_name)){
				api_token=localStorage.getItem(token_name);
			}

			var headers={
				'Accept': 'application/json',
				'Authorization': 'Bearer '+api_token,
				'Access-Control-Allow-Origin': '*'
			};

			//if(ref.api_key) headers['Api-Key']=ref.api_key;

			ref.jq().ajaxSetup({
				headers: headers
			});

		},
		limit_str: function(str, limit=20, alt_str='...'){

			if(str && str.length){
				return str.length>limit?str.substring(0, limit-3)+alt_str:str;
			}else return '';
			
		},
		wait_me: function(selector, config=null){

			var jq=this.jq();

			if(config){

				jq(selector).waitMe(config);

			}else{

				jq(selector).waitMe({
					effect: 'facebook',
					//color: '#000',
					text: 'Please! Wait ...'
				});

			}

		},
		clone: function(value){

			return Object.assign({}, value);

		},
		setup_urls: function(){
			this.base_url=this.$store.state.base_url;
			this.api_token=this.$store.state.api_token;
			//this.api_url=this.$store.state.api_url;
			//this.api_key=this.$store.state.api_key;
		},
		str_title: function(str){

			return str.replace(
				/\w\S*/g,
				function(txt) {
					return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
				}
			);

		},
		//Ruhul amin vai told you to make a component of this.
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = data.per_page * this.pagination.currentPage - data.per_page + 1
        },
        paginationCustomData (data) {
            this.paginationCustom.currentPage = data.current_page
            this.paginationCustom.totalRows = data.total
            this.paginationCustom.perPage = data.per_page
            this.paginationCustom.slOffset = data.per_page * this.paginationCustom.currentPage - data.per_page + 1
        },
        paginationVerifiedData (data) {
            this.paginationVerified.currentPage = data.current_page
            this.paginationVerified.totalRows = data.total
            this.paginationVerified.perPage = data.per_page
            this.paginationVerified.slOffset = data.per_page * this.paginationVerified.currentPage - data.per_page + 1
        },
        paginationUnverifiedData (data) {
            this.paginationUnverified.currentPage = data.current_page
            this.paginationUnverified.totalRows = data.total
            this.paginationUnverified.perPage = data.per_page
            this.paginationUnverified.slOffset = data.per_page * this.paginationUnverified.currentPage - data.per_page + 1
        },
        checkCurrentDateLessThenDate: function (dateValue) {
            const currentDate = this.dDate(new Date(), 'YYYY-MM-DD')
            const anotherDate = this.dDate(dateValue, 'YYYY-MM-DD')
            if (anotherDate > currentDate) {
                return true
            } else {
                return false
            }
        },
        checkCurrentDateGreaterThenDate: function (dateValue) {
            const currentDate = this.dDate(new Date(), 'YYYY-MM-DD')
            const anotherDate = this.dDate(dateValue, 'YYYY-MM-DD')
            if (anotherDate < currentDate) {
                return true
            } else {
                return false
            }
        },
		dDate: function (value, format='D MMM  YYYY') {
			return moment.utc(value).local().format(format)
		},
		dDateTime: function (value, format='D MMM  YYYY LT') {
			return moment.utc(value).local().format(format)
		},
		dTime: function (value, format='LT') {
			return moment.utc(value).local().format(format)
		},
		dMonth: function (value, format='MMM  YYYY') {
			return moment(value).format(format)
		},
		dYear: function (value, format='YYYY') {
			return moment(value).format(format)
		},
		dNow: function (value) {
			// return moment(value).fromNow()
			return moment.utc(value).local().fromNow()
		},
		integer: function (value = 0) {
			const integerValue = value | 0
			return Number(integerValue).toLocaleString()
		},
		float: function (value = 0.00) {
			if (value) {
				const integerValue = parseFloat(value)
				return Number(integerValue).toLocaleString()
			} else {
				return 0
			}
		},
		float2: function (value = 0.00) {
			if (value) {
				const double = parseFloat(value).toFixed(2)
				return Number(double).toLocaleString()
			} else {
				return 0
			}
		},
		nf: function(value=0.00){
			if(value) return parseFloat(value).toFixed(2);
			else return 0.00;
		},
		pureInt: function(value=0.00){
			if (value) {
				return parseInt(value)
			}else{
				return 0;
			}
		},
		hash_id: function (value, encode = true) {
			var hashids = new Hashids('hashids_for_passing_url_id', 32);
			if (encode) {
				return hashids.encode(value);
			} else {
				return hashids.decode(value);
			}
		},
		check_uni_auth: async function(){

			var ref=this;
			var api_token=localStorage.getItem('uni_api_token');

			if(!api_token){
				ref.$router.push('/login');
				return;
			}

		},
        avatarText: function (value) {
            var matches = value.match(/\b(\w)/g);
			
			if (matches.length == 1) {
				return value.substring(0, 2).toLocaleUpperCase()
			} else {
				const data = matches.filter((item, index) => {
					if (index < 2) {
						return Object.assign({}, item)
					}
				})
				return data.join(''); 
			}
        },
		countries: async function(){

			var ref=this;
            var jq=ref.jq();
            try {
                var res = await jq.get(ref.url('api/v1/common/ajax/countries'));
                return res.data.countries;

            } catch (err) {
                ref.alert(ref.err_msg(err), 'error');
            }

		},
		random_number:function(limit=10){
			return Math.floor(Math.random() * (limit+1));
		},
		remove_row: function(array_data, index){

			if(index > -1) array_data.splice(index, 1);

		},
		img_on_error: function(ev){
			ev.target.src = 'https://via.placeholder.com/150?text=No+Image';
		},
		imageRenderer: function (inputEl, callback) {
			const inputImageRenderer = () => {
				const file = inputEl.value.files[0]
				const reader = new FileReader()
			
				reader.addEventListener(
				'load',
				() => {
					callback(reader.result)
				},
				false,
				)
			
				if (file) {
				reader.readAsDataURL(file)
				}
			}
			return {
				inputImageRenderer,
			}
		},
        permission_check:function(permit_code){
			const permission_disable = this.$store.state.site.permission_disable
			if (permission_disable) {
				return true
			} else {
				if (permit_code) {
					if (permit_code == 'permitted') {
						return true
					} else {
						const permissions = this.$store.state.site.permissions
						if (permissions && permissions.length) {
							const userPermission = permissions.find(permission => permission == permit_code)
							return userPermission == undefined ? false : true
						} else {
							return false
						}
					}
				} else {
					return false
				}
			}
        },
		chunk: function (arr, len) {

			var chunks = [],
				i = 0,
				n = arr.length;
			
			while (i < n) {
				chunks.push(arr.slice(i, i += len));
			}
			
			return chunks;
		},
		humanize: function(str){
			var i, frags = str.split('_');
			for (i=0; i<frags.length; i++) {
			  frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
			}
			return frags.join(' ');
		},
		goto: function(uri){
			this.$router.push(uri);
		}
	}, //End of method,
}